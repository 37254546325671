import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Layout from '../components/Layout';
import SectionFeature from '../components/sections/SectionFeature';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';

import useResizeScreen from '../hooks/useResize';

const PlatformPage = () => {
  const { isMobile } = useResizeScreen();
  return (
    <Layout title="Jetic - Fully-managed Apache Camel on Kubernetes">
      <div className="platform-page">
        <section className="first-section first-section--common">
          <div className="container pt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="first-section__content">
                  <h1 className="header-700 mb-4">Fully-managed Apache Camel on Kubernetes</h1>
                  <h2 className="body-300 gray-500 mb-32">
                    Jetic helps you build scalable Integrations and APIs as microservices based on
                    Apache Camel deployed on any Kubernetes.
                  </h2>
                  <div className="btn-wrapper mb-3 pb-3">
                    <a
                      href="https://app.jetic.io/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-btn c-btn--primary"
                    >
                      Sign up
                    </a>
                    <a href="https://" className="c-btn c-btn--white">
                      Watch the demo
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/platform/Illustration-main.png"
                    className="first-section__image illustration w-100"
                    alt="Manage Hybrid Integration Clusters"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/platform/Illustration-main--mobile.png"
                    className="first-section__image illustration w-100"
                    alt="Manage Hybrid Integration Clusters"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            {isMobile && (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-introducing-jetic--mobile.png"
                className="illustration mb-32 w-100"
                alt="Introducing Jetic Platform for Apache Camel K"
              />
            )}
            <div className="heading-wrapper">
              <h3 className="header-600 mb-3">Introducing Jetic Platform for Apache Camel K</h3>
              <h4 className="body-300 gray-400 pb-md-80">
                Without coding - develop, run and operate enterprise-ready APIs and Integrations
                remarkably fast.
              </h4>
            </div>
            {!isMobile && (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-introducing-jetic.png"
                className="illustration w-100"
                alt="Introducing jetic Platform for Apache Camel K"
              />
            )}
          </div>
        </section>

        <section className="c-section--gray">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/platform/Illustration-86%.png"
                    className="illustration w-100"
                    alt="86% of IT leaders say the most innovative companies are using enterprise open source*"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/platform/Illustration-86%--mobile.png"
                    className="illustration w-100"
                    alt="86% of IT leaders say the most innovative companies are using enterprise open source*"
                  />
                )}
              </div>
              <div className="col-md-5 offset-md-1">
                <div
                  className={classNames('d-flex flex-column justify-content-center h-100', {
                    'text-center': isMobile,
                    'mt-4': isMobile,
                  })}
                >
                  <h3 className="header-600 mb-2">
                    86% of IT leaders say the most innovative companies are using enterprise open
                    source*
                  </h3>
                  <h4 className="body-300 gray-400">
                    *Red Hat Open Source Study 2020
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            <SectionFeature
              sectionClassName="pb-80"
              contentTitleText="Camel Studio"
              contentBodyTags={
                <>
                  <h4 className="header-300 mb-2">Low-code</h4>
                  <p className="body-300 gray-400 mb-4">
                    Drastically reduce the complexity and time needed to build Integrations thanks
                    to our easy to use GUI.
                  </p>

                  <h4 className="header-300 mb-2">Limitless</h4>
                  <p className="body-300 gray-400 mb-4">
                    Our plugable Camel Studio offers the full potential to build enterprise-ready
                    Integration Hubs
                  </p>

                  <h4 className="header-300 mb-2">No Vendor Lock-in</h4>
                  <p className="body-300 gray-400 mb-5">
                    Retain access to the entire source-code in your Git
                    repository
                  </p>

                  <a
                    href="https://jetic.io/camel-studio"
                    className={classNames('c-btn c-btn--white', {
                      'w-100': isMobile,
                    })}
                  >
                    Learn more
                  </a>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/platform/Illustration-camel-studio.png"
                    alt="Camel Studio"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/platform/Illustration-camel-studio--mobile.png"
                    alt="Camel Studio"
                  />
                ),
              }}
            />
            <SectionFeature
              sectionClassName="py-80"
              contentTitleText="API Builder"
              contentBodyTags={
                <>
                  <h4 className="header-300 mb-2">Data-as-a-service</h4>
                  <p className="body-300 gray-400 mb-4">
                    Design and expose scalable APIs to retain control of your data.
                  </p>

                  <h4 className="header-300 mb-2">Generate OpenAPI</h4>
                  <p className="body-300 gray-400 mb-4">
                    Using point-and-click tooling generate OpenAPI compliant APIs. Initially known as
                    Swagger.
                  </p>

                  <h4 className="header-300 mb-2">Scale & Secure</h4>
                  <p className="body-300 gray-400 mb-5">
                    Once implemented easily scale and secure your APIs using industry-standard
                    technologies.
                  </p>

                  <a
                    href="https://jetic.io/api-builder"
                    className={classNames('c-btn c-btn--white', {
                      'w-100': isMobile,
                    })}
                  >
                    Learn more
                  </a>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/platform/Illustration-api-builder.png"
                    alt="Camel Studio"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/platform/Illustration-api-builder--mobile.png"
                    alt="Camel Studio"
                  />
                ),
              }}
            />
            <SectionFeature
              sectionClassName="py-80"
              contentTitleText="Cloud Data Mapper"
              contentBodyTags={
                <>
                  <h4 className="header-300 mb-2">Transform Data</h4>
                  <p className="body-300 gray-400 mb-4">
                    Graphical interface to seamlessly build complex data mappings.
                  </p>

                  <h4 className="header-300 mb-2">Data Formats</h4>
                  <p className="body-300 gray-400 mb-4">
                    Supports industry-standard data formats as XML, JSON, CSV, Java, and many more.
                  </p>

                  <h4 className="header-300 mb-2">Scale & Secure</h4>
                  <p className="body-300 gray-400 mb-5">
                    Again, retain access to your investment while we generate mappings based on
                    opens-source framework Atlasmap.
                  </p>

                  <a
                    href="https://jetic.io/cloud-mapper"
                    className={classNames('c-btn c-btn--white', {
                      'w-100': isMobile,
                    })}
                  >
                    Learn more
                  </a>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/platform/Illustration-cloud-mapper.png"
                    alt="Camel Studio"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/platform/Illustration-cloud-mapper--mobile.png"
                    alt="Camel Studio"
                  />
                ),
              }}
            />
            <SectionFeature
              sectionClassName="pt-80"
              contentTitleText="Control Hub"
              contentBodyTags={
                <>
                  <h4 className="header-300 mb-2">Manage Deployments</h4>
                  <p className="body-300 gray-400 mb-4">
                    One-click microservice deployment strategies without the engineering.
                  </p>
                  <h4 className="header-300 mb-2">Monitor</h4>
                  <p className="body-300 gray-400 mb-4">
                    Easily observe the status of your entire deployment cluster and dive deeper if
                    required.
                  </p>
                  <h4 className="header-300 mb-2">Relese</h4>
                  <p className="body-300 gray-400 mb-5">
                    Promoting and releasing artifacts between stages has never been easier.
                  </p>
                  <a
                    href="https://jetic.io/control-hub"
                    className={classNames('c-btn c-btn--white', {
                      'w-100': isMobile,
                    })}
                  >
                    Learn more
                  </a>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/platform/Illustration-control-hub.png"
                    alt="Control Hub"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/platform/Illustration-control-hub--mobile.png"
                    alt="Control Hub"
                  />
                ),
              }}
            />
          </div>
        </section>

        <section className="c-section--gray py-80">
          <div className="container">
            <div className="heading-wrapper">
              <h3 className="header-600 mb-3">Cloud-native Ecosystem</h3>
              <h4 className="body-300 gray-400 pb-80">
                Purely open-source. No vendor lock-in. Maximum maturity.
              </h4>
            </div>
            {!isMobile ? (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-cloud-native.png"
                className="illustration w-100"
                alt="Cloud-native Ecosystem"
              />
            ) : (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-cloud-native--mobile.png"
                className="illustration w-100"
                alt="Cloud-native Ecosystem"
              />
            )}
          </div>
        </section>

        <section className="c-section--white py-80">
          <div className="container">
            <div className="heading-wrapper">
              <h3 className="header-600 mb-3">True Hybrid Integration. Scale Anywhere</h3>
              <h4 className="body-300 gray-400 pb-80">
                We help you to unlock the full cloud-native potential while keeping your data safe
                within your trusted datacenter.
              </h4>
            </div>
            {!isMobile ? (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-hybrid-integration.png"
                className="illustration w-100"
                alt="True Hybrid Integration. Scale Anywhere"
              />
            ) : (
              <StaticImage
                src="../assets/images/pages/platform/Illustration-hybrid-integration--mobile.png"
                className="illustration w-100"
                alt="True Hybrid Integration. Scale Anywhere"
              />
            )}
          </div>
        </section>

        <section className="py-80">
          <div className="container">
            <div className="row ps-md-5 align-items-center">
              <div className="col-md-6">
                <p className="body-600 gray-600 mb-5 mb-md-0 quote">
                  We made open-source integration scalable, automated and easy to use for the people
                  who build and maintain it.
                </p>
              </div>
              <div className="col-md-5 offset-md-1">
                <StaticImage
                  src="../assets/images/pages/platform/Illustration-andre.png"
                  className="illustration w-100"
                  alt="Andre Sluczka"
                  height={460}
                />
              </div>
            </div>
          </div>
        </section>

        <SectionEarlyAccess />
      </div>
    </Layout>
  );
};

export default PlatformPage;
